<template>
  <div class="image-container">
    <img
      src="@/assets/fondo3.jpg"
      alt="Imagen de ejemplo"
      class="cover-image"
    >
  </div>
</template>

<script>
export default {
  name: 'CoverImageComponent',
};
</script>

<style scoped>
.image-container {
  width: 100vw; /* Ocupar el 100% del ancho de la ventana */
  height: 400px; /* Ajusta la altura según tus necesidades */
  overflow: hidden; /* Oculta cualquier parte de la imagen que se salga del contenedor */
  position: relative;
  margin: 0; /* Elimina márgenes para evitar desplazamiento */
}

.cover-image {
  width: 100%; /* La imagen ocupará el 100% del ancho del contenedor */
  height: 100%; /* La imagen abarcará el 100% de la altura del contenedor */
  object-fit: cover; /* Ajuste tipo cover para que la imagen se ajuste proporcionalmente */
  position: absolute; /* Fija la imagen dentro del contenedor */
  top: 0;
  left: 0;
}
</style>
