<template>
  <div class="container">
    <div class="background-blur" /> <!-- Fondo borroso -->
    <div class="content">
      <!-- Texto "Nos Casamos" con animación de izquierda a derecha -->
      <div
        ref="textRef"
        class="text"
      >
        ¡Nos Casamos!
      </div>
      <div
        ref="text2Ref"
        class="text-2"
      >
        <!-- Puedes agregar contenido adicional aquí -->
      </div>
      <!-- Imagen con animación de escala de pequeño a grande -->
      <img
        ref="fechasaveRef"
        :src="fechasave"
        alt="fotosaveday"
        class="fotosaveday"
      >
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
  name: 'TuComponente',
  setup() {
    const textRef = ref(null);
    const text2Ref = ref(null);
    const fechasaveRef = ref(null); // Referencia para la imagen
    const fechasave = require('../assets/fechasave.png'); // Importar imagen

    onMounted(() => {
      // Elementos a observar
      const elements = [textRef.value, fechasaveRef.value]; // Observar texto y la imagen

      const observerOptions = {
        root: null, // Usar el viewport como raíz
        rootMargin: '0px',
        threshold: [0.1] // Activar cuando el 10% del elemento sea visible
      };

      const observerCallback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const element = entry.target;

            if (element === textRef.value) {
              element.classList.add('animate-slideInLeft'); // Animación de izquierda a derecha
            } else if (element === fechasaveRef.value) {
              element.classList.add('animate-scaleIn'); // Animación de escala para la imagen
            }

            element.addEventListener('animationend', () => {
              if (element === textRef.value) {
                element.classList.remove('animate-slideInLeft');
              } else if (element === fechasaveRef.value) {
                element.classList.remove('animate-scaleIn');
              }
            }, { once: true });
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      // Observar cada elemento
      elements.forEach(element => {
        if (element) {
          observer.observe(element);
        }
      });
    });

    return { textRef, text2Ref, fechasaveRef, fechasave };
  }
};
</script>

<style scoped>
/* Importación de fuentes */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..800&display=swap');

.container {
  width: 100vw;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*background-attachment: fixed;*/
  z-index: -1;
 background-image: url('../assets/fondo4.png');
}

.content {
  max-width: 80%;
  width: 100%;
  padding: 0 20px;
  text-align: center;
}

.text {
  font-size: 80px;
  font-family: 'Dancing Script', cursive;
  font-weight: 800;
  margin-top: 10px;
  background: linear-gradient(270deg, #393433, #d0c4c4, #7d5f59);
  background-size: 200% 100%;
  background-position: 0 0;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: animateText 3s linear infinite;
}

@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.text-2 {
  font-size: 36px;
  color: rgba(46, 44, 44, 0.678);
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
  margin-top: 20px;
}

.fotosaveday {
  width: 100%;
  max-width: 900px;
  height: auto;
  margin-top: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* Ajuste responsivo para pantallas pequeñas */
@media (max-width: 768px) {
  .text {
    font-size: 42px;
  }

  .text-2 {
    font-size: 18px;
    font-weight: 600;
  }

  .fotosaveday {
    width: 100%;
    height: 180px;
    object-fit: cover;
    margin-top: 10px;
  }
}

/* Animación para el texto desde la izquierda */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slideInLeft {
  animation: slideInLeft 1s ease-out;
}

/* Animación de escala para la imagen (pequeño a grande) */
@keyframes scaleIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-scaleIn {
  animation: scaleIn 1s ease-out;
}

.fotosaveday:hover {
  transform: scale(1.05);
}
</style>
