<template>
  <div class="container d-flex align-items-center justify-content-center vh-100">
    <!-- Overlay con el formulario de confirmación de asistencia -->
    <div class="overlay bg-light p-5 rounded-4 shadow-lg">
      <div class="confirmation-container">
        <div class="custom-title mb-4 text-center">
          Confirmación de Asistencia
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="form-group mb-3">
            <label
              for="name"
              class="form-label"
            >Nombre</label>
            <input
              id="name"
              v-model="name"
              type="text"
              class="form-control"
              placeholder="Ingresa tu nombre"
              required
            >
          </div>

          <div class="form-group mb-3">
            <label
              for="phone"
              class="form-label"
            >Teléfono</label>
            <input
              id="phone"
              v-model="phone"
              type="tel"
              class="form-control"
              placeholder="Ingresa tu teléfono"
              required
            >
          </div>

          <div class="form-group mb-3">
            <label class="form-label">¿Invitado por?</label>
            <div class="d-flex justify-content-start gap-4">
              <div class="form-check">
                <input
                  id="novia"
                  v-model="invitedBy"
                  type="radio"
                  value="novia"
                  class="form-check-input"
                  required
                >
                <label
                  class="form-check-label"
                  for="novia"
                >Novia</label>
              </div>
              <div class="form-check">
                <input
                  id="novio"
                  v-model="invitedBy"
                  type="radio"
                  value="novio"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="novio"
                >Novio</label>
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label class="form-label">¿Asistirás?</label>
            <div class="d-flex justify-content-start gap-4">
              <div class="form-check">
                <input
                  id="si"
                  v-model="attending"
                  type="radio"
                  value="si"
                  class="form-check-input"
                  required
                >
                <label
                  class="form-check-label"
                  for="si"
                >Sí</label>
              </div>
              <div class="form-check">
                <input
                  id="no"
                  v-model="attending"
                  type="radio"
                  value="no"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="no"
                >No</label>
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label
              for="guests"
              class="form-label"
            >¿Cuántos asisten?</label>
            <select
              id="guests"
              v-model="guests"
              class="form-select"
              :class="{ 'loading': loading }"
            >
              <option
                value=""
                disabled
                selected
              >
                Selecciona una opción
              </option> <!-- Opción predeterminada -->
              <option
                v-for="n in guestsOptions"
                :key="n"
                :value="n"
              >
                {{ n }}
              </option>
            </select>
          </div>


          <button
            type="submit"
            class="btn btn-primary w-100"
          >
            Confirmar
          </button>
          <div class="mt-3">
            <div
              v-if="successMessage.length"
              class="alert alert-success text-center"
              :class="{ 'alert-danger': successMessage.includes('¡Intenta de nuevo!') }"
            >
              <div
                v-for="(line, index) in successMessage"
                :key="index"
              >
                {{ line }}<br v-if="index < successMessage.length - 1">
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import baseUrl from '../api';  // Asegúrate de importar la URL base

export default {
  name: 'ConfirmacionAsistencia',
  props: {
    idInvitado: {
      type: Number,
      required: true,
      validator(value) {
        return !isNaN(value) && value > 0;
      }
    },
    nombre: {
      type: String,
      default: '',
    },
    idFestejado: {
      type: Number,
      default: null,
    }
  },
  setup(props) {
    const name = ref('');
    const phone = ref('');
    const invitedBy = ref('');
    const attending = ref('');
    const guests = ref('1');  // Valor por defecto
    const guestsOptions = ref([1, 2]);  // Opciones por defecto
    const successMessage = ref('');  // Definir successMessage
    const loading = ref(true); // Estado de carga

    const fetchGuestLimit = async () => {
  try {
    const paqueteUrl = `${baseUrl}/${props.nombre ? `${props.nombre}/` : ''}${props.idFestejado ? `${props.idFestejado}/` : ''}${props.idInvitado ? `${props.idInvitado}` : ''}`;

    const response = await axios.get(paqueteUrl); // Aquí realizas la solicitud

    const data = response.data; // Accede a los datos directamente
    const numPases = data.num_pase; // Verifica que el nombre del campo sea correcto

    if (numPases === null || numPases === 0) {
      guestsOptions.value = [1, 2];
    } else {
      guestsOptions.value = Array.from({ length: numPases }, (_, i) => i + 1);
    }
  } catch (error) {
    console.error('Error al obtener el número de pases:', error);
  }
  finally {
    loading.value = false; // Cambia el estado de carga
  }
};
    onMounted(() => {
      fetchGuestLimit();
    });

    const handleSubmit = async () => {
      if (!name.value || !phone.value || !invitedBy.value || !attending.value || !guests.value) {
        successMessage.value =  [
  '¡Faltan datos, completa el formulario!'
];        name.value = '';
        return;
      }

      const formData = {
        nombre_asistencia: name.value,
        telefono_invitado: phone.value,
        invitedby: invitedBy.value,
        asistira: attending.value,
        id_festejada: props.idFestejado,  // Agregar el parámetro aquí
        id_invitado: props.idInvitado,  // Agregar el parámetro aquí

        num_pases: guests.value,
      };

      try {
        const response = await fetch(`${baseUrl}/asistencia`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error('Error al enviar el formulario.');
        }

        successMessage.value = [
  '¡Confirmación exitosa!',
  'Es muy importante que cualquier cancelación o cambio notificarla o volver a hacer tu registro.'
];        name.value = '';
        phone.value = '';
        invitedBy.value = '';
        attending.value = '';
        guests.value = '1';
      } catch (error) {
        successMessage.value = '¡Intenta de nuevo!';
      }
    };

    return {
      name,
      phone,
      invitedBy,
      attending,
      guests,
      guestsOptions,
      successMessage, // Asegúrate de devolver el mensaje

      handleSubmit,
    };
  },
};
</script>

<style scoped>
/* Estilos opcionales */
</style>
<style scoped>
.container {
  background-image: url('@/assets/dreesscode.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.overlay {
  max-width: 500px;
  width: 100%;
}

.title {
  color: #333;
}

.form-check-input {
  cursor: pointer;
}

.btn {
  background-color: #755e53;
  border-color: #785b4d;
}

.btn:hover {
  background-color: #0056b3;
}


.form-select {
  background-color: #fff; /* Color de fondo blanco */
  transition: background-color 0.3s ease, opacity 0.3s ease;
  position: relative; /* Asegúrate de que esté en la posición correcta */

  transition: background-color 0.3s ease, opacity 0.3s ease;
  opacity: 1; /* Asegúrate de que sea visible */
}

.form-select:disabled {
  opacity: 0.1; /* Cambia l
  a opacidad mientras se carga */
}

.custom-title {
  font-size: 1.3rem;
  text-align: center;
  color: #fffafa;
  font-family: 'Dancing Script', cursive;
  font-weight: 600;
  background-color: rgba(168, 132, 122, 0.95);
}
.alert {
  padding: 15px;
  border-radius: 5px;
}
</style>
