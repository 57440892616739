<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      ¡Que nos acompañes en lo más importante! Y sí esta en tu disposición realizar una muestra de cariño estaremos muy agradecidos
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css'; // Asegúrate de importar animate.css

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const animationDone = ref(false); // Para controlar si la animación ya se ha hecho

    onMounted(async () => {
      await nextTick(); // Asegurarte de que el DOM esté completamente actualizado

      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        // Configuración del IntersectionObserver
        const observerOptions = {
          root: null, // Usar el viewport como raíz
          rootMargin: '0px', // Ajustar si es necesario
          threshold: [0.1] // Activar cuando el 10% del elemento sea visible
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                // Si el elemento es visible y la animación no ha sido realizada
                element.classList.add('animate__animated', 'animate__fadeInUp');
                element.addEventListener('animationend', () => {
                  element.classList.remove('animate__animated', 'animate__fadeInUp');
                  element.style.opacity = '1'; // Asegúrate de que el elemento se mantenga visible
                }, { once: true });

                // Marca la animación como realizada
                animationDone.value = true;
              }
            } else {
              // Si el elemento se vuelve invisible, resetea la animación
              element.style.opacity = '0'; // Ocultar el elemento cuando no es visible
              // Marca la animación como no realizada
              animationDone.value = false;
            }
          });
        };

        // Crear un nuevo IntersectionObserver
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  margin: 0; /* Elimina cualquier margen alrededor del contenedor */
  text-align: center;
  background-color: rgba(255, 255, 255, 0.95);
  opacity: 0;
  transform: translateY(0);
  z-index: 1;
  font-size: 26px;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-weight: 300;
}

/* Aplicar animación y mantener el estado final */
.animate__animated {
  animation-fill-mode: both; /* Mantiene el estado final de la animación */
}
</style>
