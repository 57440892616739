<template>
  <div>
    <!-- Contenedor Mensaje de Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <div
        ref="mensajeRef"
        class="mensaje-bienvenida"
      >
        ¡Cuando te das cuenta que deseas pasar el resto de tu vida con alguien, deseas con todas tus fuerzas que ese momento llegue cuando antes!
      </div>

      <!-- Nuevo texto adicional -->
      <div class="nuevo-texto">
        <br>Emma & Diego
      </div>

      <!-- Ícono entre los dos textos -->
      <div class="icono-container">
        <i class="fas fa-heart icono" />
      </div>
    </div>

    <!-- Resto del contenido... -->
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css'; // Asegúrate de importar animate.css

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const animationDone = ref(false);

    onMounted(async () => {
      await nextTick();
      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: [0.1]
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                element.classList.add('animate__animated', 'animate__fadeInUp');
                element.addEventListener('animationend', () => {
                  element.classList.remove('animate__animated', 'animate__fadeInUp');
                  element.style.opacity = '1';
                }, { once: true });

                animationDone.value = true;
              }
            } else {
              element.style.opacity = '0';
              animationDone.value = false;
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef };
  }
};
</script>

<style scoped>
/* Contenedor Mensaje de Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 30px;
  margin: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.95);
  opacity: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../assets/fondo4.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s;
}

.bienvenida-container:hover {
  transform: translateY(-5px);
}

.mensaje-bienvenida {
  font-size: 40px;
  font-weight: 300;
  font-family: 'Caveat', cursive;
  color: rgb(52, 46, 44);
  transition: color 0.3s;
}

/* Estilos para el nuevo texto adicional */
.nuevo-texto {
  font-size: 18px;
  font-family: 'Dancing Script', cursive; /* Fuente cursiva elegante */
  font-weight: 400;
  color: rgba(35, 32, 34, 0.9);
  margin-bottom: 20px; /* Espacio entre el nuevo texto y el ícono */
  text-align: center; /* Centrar el texto */
  transition: color 0.3s ease-in-out;
}

.nuevo-texto:hover {
  color: rgba(255, 182, 193, 1); /* Cambia a un tono más suave al pasar el ratón */
}


.mensaje-bienvenida:hover {
  color: rgba(255, 105, 180, 0.8);
}

/* Contenedor del ícono */
.icono-container {
  margin: 20px 0;
}

/* Estilos para el ícono */
.icono {
  font-size: 32px;
  color: rgba(255, 105, 180, 0.7);
  animation: pulsar 1.5s infinite;
}

/* Animación para el ícono */
@keyframes pulsar {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Estilos para el texto adicional */
.texto-adicional {
  font-size: 24px;
  font-weight: 300;
  font-family: 'Caveat', cursive;
  margin-top: 20px;
}

/* Estilos para el contenedor de mensajes */
.mensaje-container {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid rgba(255, 105, 180, 0.7);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.mensaje-container:hover {
  transform: scale(1.02);
}

.mensaje-container h2 {
  margin-bottom: 10px;
}

.mensaje-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(52, 46, 44, 0.5);
  border-radius: 5px;
}

/* Estilos para el campo de nombre */
.nombre-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(52, 46, 44, 0.5);
  border-radius: 5px;
}

/* Estilos para el botón */
.mensaje-container button {
  padding: 10px 20px;
  background-color: rgba(255, 105, 180, 0.8);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.mensaje-container button:hover {
  background-color: rgba(255, 105, 180, 1);
  transform: scale(1.05);
}

/* Estilos para los mensajes enviados como tarjetas */
.mensajes-enviados {
  margin-top: 20px;
  text-align: left;
}

.mensajes-enviados h3 {
  margin-bottom: 10px;
}

/* Estilo para cada tarjeta de mensaje */
.mensaje-tarjeta {
  padding: 10px;
  margin: 5px 0;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.mensaje-tarjeta:hover {
  transform: translateY(-5px) rotateY(5deg);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}
</style>
