<template>
  <div class="full-width-image">
    <img
      src="../assets/3.jpg"
      alt="Descripción de la imagen"
    >
  </div>
</template>
  
  <script>
  export default {
    name: 'FullWidthImage',
  };
  </script>
  
  <style scoped>
  /* Elimina el margen y el padding del body para evitar barras de desplazamiento */
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Evita que aparezca la barra de desplazamiento horizontal */
  }
  
  /* Contenedor de la imagen */
  .full-width-image {
    width: 100%;
    overflow: hidden; /* Oculta cualquier contenido que exceda el contenedor */
  }
  
  .full-width-image img {
    width: 100%;
    height: auto; /* Mantiene la relación de aspecto de la imagen */
    display: block; /* Elimina el espacio debajo de la imagen */
  }
  </style>
  