<template>
  <div class="timeline-container">
    <!-- Overlay -->
    <div class="overlay">
      <!-- Columna izquierda con icono y texto -->
      <div class="timeline-left">
        <div
          ref="leftTextRef1"
          class="text"
        >
          Itinerario
        </div>
        <div
          ref="rightIconRef"
          class="icon"
        >
          <img
            src="@/assets/iglesia-itinerario1.gif"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div
          ref="leftTextRef2"
          class="text2"
        >
          Ceremonia
        </div>
        <div
          ref="leftTextRef3"
          class="text3"
        >
          20:00 pm-20:30pm
        </div>
      </div>
              
      <!-- Línea vertical -->
      <div class="timeline-line" />
              
      <!-- Columna derecha con icono y texto -->
      <div class="timeline-right">
        <div
          ref="rightIconRef"
          class="icon"
        >
          <img
            src="@/assets/cheers-itinerario.svg"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div
          ref="rightTextRef2"
          class="text2"
        >
          Recepcón
        </div>
        <div
          ref="rightTextRef3"
          class="text3"
        >
          21:00am-10:00pm
        </div>
        <div
          ref="rightIconRef"
          class="icon"
        >
          <img
            src="@/assets/cena-itinerario.svg"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div
          ref="rightTextRef4"
          class="text2"
        >
          Cena
        </div>
        <div
          ref="rightTextRef5"
          class="text3"
        >
          22:00am-22:30pm
        </div>
      </div>
      <div
        ref="rightIconRef"
        class="icon"
      >
        <img
          src="@/assets/fiesta-itinerario.svg"
          alt="icon-right"
          class="icon-image"
        >
      </div>
      <div
        ref="rightTextRef6"
        class="text2"
      >
        Fiesta
      </div>
      <div
        ref="rightTextRef7"
        class="text3"
      >
        22:30am-2:00am
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';

export default {
  name: 'TimelineComponent',
  setup() {
    const leftIconRef = ref(null);
    const leftTextRef1 = ref(null);
    const leftTextRef2 = ref(null);
    const leftTextRef3 = ref(null);

    const rightIconRef = ref(null);
    const rightTextRef1 = ref(null);
    const rightTextRef2 = ref(null);
    const rightTextRef3 = ref(null);
    const rightTextRef4 = ref(null);
    const rightTextRef5 = ref(null);
    const rightTextRef6 = ref(null);
    const rightTextRef7 = ref(null);
    const elements = [
      leftIconRef,
      leftTextRef1,
      leftTextRef2,
      leftTextRef3,
      rightIconRef,
      rightTextRef1,
      rightTextRef2,
      rightTextRef3,
      rightTextRef4,
      rightTextRef5,
      rightTextRef6,
      rightTextRef7,
    ];

    onMounted(() => {
      nextTick(() => {
        const observerOptions = {
          root: null,
          threshold: [0, 0.1],
        };

        const observerCallback = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Aplicar animación solo si el elemento no tiene ya la clase
              if (!entry.target.classList.contains('animate-fadeInUp')) {
                entry.target.classList.add('animate-fadeInUp');
              }
            } else {
              // Si el elemento se aleja del viewport y ya tiene la clase de animación, removerla para reiniciar la animación
              if (entry.target.classList.contains('animate-fadeInUp')) {
                entry.target.classList.remove('animate-fadeInUp');
              }
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        elements.forEach((element) => {
          if (element.value) {
            observer.observe(element.value);
          }
        });
      });
    });

    return {
      leftIconRef,
      leftTextRef1,
      leftTextRef2,
      leftTextRef3,
      rightIconRef,
      rightTextRef1,
      rightTextRef2,
      rightTextRef3,
      rightTextRef4,
      rightTextRef5,
      rightTextRef6,
      rightTextRef7,
    };
  },
};
</script>

<style scoped>
/* Contenedor general del timeline */
/* Contenedor general del timeline */
.timeline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh; /* Ajusta la altura al 100% de la ventana */
  padding: 0;
  box-sizing: border-box;
  overflow: hidden; /* Evita el scroll */
  margin: 0;
  background-image: url('../assets/bg26.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 10;
}

/* Ajustes de overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Asegúrate que ocupe toda la altura */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  z-index: 0;
  padding: 20px 20px 5px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  transition: background-color 0.5s ease, backdrop-filter 0.5s ease;
  box-sizing: border-box;
  overflow: hidden; /* Evita el scroll dentro del overlay */
}


/* Línea vertical */
.timeline-line {
  width: 2px;
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: -2;
  pointer-events: none;
}

/* Columnas para iconos y texto */
.timeline-left,
.timeline-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 5px;
  box-sizing: border-box;
  z-index: 10;
}

/* Icono */
.icon {
  margin-bottom: 0px;
  margin-top: 5px;
  transform: scale(0.5); /* Escala el ícono por un factor de 1.5 */
  height: auto; /* Mantiene las proporciones */
}

/* Icono de imagen */
.icon-image {
  width: 100%;
  max-width: 100px;
  height: auto;
  animation: pulse 2s infinite;
}

/* Texto */
.text {
  font-size: 36px;
  text-align: center;
  color: #fffafa;
  font-family: 'Dancing Script', cursive;
  font-weight: 600;
  margin-top: 0px;
}

.text2 {
  font-size: 30px;
  text-align: center;
  color: rgba(46, 44, 44, 0.9);
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Dancing Script', cursive;
  font-weight: 600;
}

.text3 {
  font-size: 20px;
  text-align: center;
  color: rgba(46, 44, 44, 0.9);
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: 600;
}

/* Animaciones */
@keyframes colorChange {
  0% {
    background-color: transparent;
  }
  100% {
    color: #ffffff;
  }
}

.animate-fadeInUp {
  animation: colorChange 3s ease-in-out forwards;
}




/* Ajustes responsivos */
@media (min-width: 769px) {
  .timeline-container {
    flex-direction: row;
  }

  .timeline-left,
  .timeline-right {
    width: 150px;
  }

  .timeline-line {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .timeline-container {
    flex-direction: column;
  }

  .text {
    font-size: 42px;
  }

  .timeline-line {
    height: 200px;
  }

  .icon-image {
    width: 20px;
  }
}

@media (max-width: 480px) {
  .timeline-left,
  .timeline-right {
    width: 100%;
  }

  .icon-image {
    width: 70px;
  }
}

/* Estilos de animación pulse */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

</style>
