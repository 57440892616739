<template>
  <div>
    <!-- Barra de navegación con transición -->
    <transition name="fade">
      <div
        v-if="isVisible"
        :class="{ 'navbar': true, 'hidden': isNavbarHidden }"
      >
        <!-- Texto animado centrado -->
        <div class="animated-text">
          <p>{{ text }}</p>
        </div>

        <!-- Ícono de menú hamburguesa alineado a la derecha -->
        <div
          class="menu-icon"
          @click="toggleMenu"
        >
          &#9776; <!-- Ícono de menú hamburguesa -->
        </div>

        <!-- Menú desplegable, se muestra solo cuando showMenu es true -->
        <transition name="fade">
          <div
            v-if="showMenu"
            class="menu"
          >
            <a href="#">Inicio</a>
            <a href="#">Nosotros</a>
            <a href="#">Contacto</a>
          </div>
        </transition>
      </div>
    </transition>

    <!-- Imagen de portada con texto y cuenta regresiva -->
    <transition name="fade">
      <div
        v-if="isVisible"
        class="fotoportada-container animate__animated animate__fadeIn"
      >
        <div class="overlay">
          <!-- Texto centrado sobre la imagen -->
          <p class="centered-text">
            {{ textsobreimagen }}
          </p>
          <p class="centered-fechatext">
            {{ fechaevento }}
          </p>
          <!-- Contenedor de cuenta regresiva justo debajo del texto -->
          <div class="countdown">
            <div class="countdown-item">
              <span>{{ countdownText.substring(0, 2) }}</span>
              <label>Dias</label>
            </div>
            <div class="countdown-item">
              <span>{{ countdownText.substring(3, 5) }}</span>
              <label>Horas</label>
            </div>
            <div class="countdown-item">
              <span>{{ countdownText.substring(6, 8) }}</span>
              <label>Minutos</label>
            </div>
            <div class="countdown-item">
              <span>{{ countdownText.substring(9, 11) }}</span>
              <label>Segundos</label>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>



<script>
import portadaP from '../assets/portada-1.jpg';
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'NavbarComponent',
  setup() {
    const fotoPortada = portadaP;
    const isVisible = ref(true);
    const text = ref('E | D');
    const showMenu = ref(false);
    const isNavbarHidden = ref(false);
    const scrollThreshold = ref(100);
    let lastScrollTop = 0;
    const textsobreimagen = ref('Emma & Diego');
    const countdownText = ref('00:00:00:00');
    const fechaevento=ref('31 Diembre 2024')

    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop && currentScrollTop > scrollThreshold.value) {
        isNavbarHidden.value = true;
      } else if (currentScrollTop < lastScrollTop) {
        isNavbarHidden.value = false;
      }

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    let countdownInterval;
    const updateCountdown = () => {
  const endDate = new Date('2024-12-31T20:50:00');
  const now = new Date();
  const difference = endDate - now;
  if (difference <= 0) {
    clearInterval(countdownInterval);
    countdownText.value = '00:00:00:00';
    return;
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  countdownText.value = `${String(days).padStart(2, '0')} ${String(hours).padStart(2, '0')} ${String(minutes).padStart(2, '0')} ${String(seconds).padStart(2, '0')}`;
};

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      updateCountdown();
      countdownInterval = setInterval(updateCountdown, 1000);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(countdownInterval);
    });

    return {
      textsobreimagen,
      fechaevento,
      countdownText,
      fotoPortada,
      isVisible,
      text,
      showMenu,
      toggleMenu,
      isNavbarHidden,
      scrollThreshold
    };
  },
};
</script>

<style scoped>
/* Barra de navegación */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(234, 219, 219, 0.95);
  color:  rgba(234, 219, 219, 0.95);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: top 0.3s;
  width: 100vw; /* Cambiar a vw para que ocupe el 100% del viewport */
  max-width: 100%; /* Limitar el ancho máximo para evitar desbordamiento */
  box-sizing: border-box; /* Asegura que padding no cause desbordamiento */
  z-index: 10;
  transition: top 0.3s;
}

.navbar.hidden {
  top: -80px;
}

/* Texto animado */
.animated-text {
  flex-grow: 1;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active en Vue <2.1.8 */ {
  opacity: 0;
}

.animated-text p {
  font-family: 'Dancing Script', cursive;
  font-size: 24px;
  font-weight: bold;
  color: #807474;
  animation: scaleText 1s infinite alternate;
}

@keyframes scaleText {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

/* Estilo del menú desplegable */
.menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(234, 219, 219, 0.95);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.menu a:hover {
  color: #007bff;
}

/* Asegurar visibilidad del ícono de menú hamburguesa */
.menu-icon {
  cursor: pointer;
  font-size: 24px;
  color: #333;
  position: absolute; /* Cambié a posición absoluta */
  top: 10px; /* Ajusté la posición para que siempre esté visible */
  right: 20px; /* Alineado a la derecha */
  z-index: 1000; /* Para que esté por encima de otros elementos */
}


.navbar {
  width: 100vw; /* Asegura que el navbar ocupe solo el ancho del viewport */
  box-sizing: border-box;
  top: 0; /* Asegúrate de que esté en la parte superior */
  left: 0; /* Asegúrate de que esté alineada a la izquierda */
  right: 0; /* Asegúrate de que esté alineada a la derecha */

}


.fotoportada-container {
  position: relative;
  width: 100%; /* Asegúrate de que ocupe todo el ancho */
  min-height: 100vh; /* Mantén esto para que ocupe toda la altura */
  overflow: hidden;
  background-image: url('../assets/portada-1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position: top; /* Asegúrate de que empiece desde la parte superior */
  top: 0; /* Asegúrate de que esté alineada a la parte superior */
  left: 0; /* Alineado a la izquierda */
}

.overlay {
  position: absolute; /* Cambiado a absolute para cubrir toda la imagen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centra el contenido en el medio */
  color: white;
  z-index: 2;
  padding-top: 100px; /* Añade padding superior */
}


.fotoPortada {
  width: 100%;
  height: 100%;
}



.centered-text {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom:0px;
  font-family: 'Dancing Script', cursive;


}

.centered-fechatext {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: rgba(237, 230, 230, 0.829); /* Color del texto */
  margin-top: 0; /* Asegúrate de que no haya margen superior */
  font-family: 'Noto Serif', serif;

  /* Estilos de fondo */
  background-color: rgba(83, 72, 72, 0.5); /* Fondo semitransparente */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px; /* Espaciado interno */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Sombra para profundidad */
  max-width: 80%; /* Ancho máximo para que no ocupe toda la pantalla */
  margin: 10px auto; /* Centrar el elemento con margen superior e inferior */
}

.countdown {
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: white;


}

.countdown-item {
  border-radius: 6px;
  padding: 8px 12px;
  text-align: center;
}

.countdown-item span {
  display: block;
  font-size: 24px;
  font-weight: bold;
  font-family: 'Noto Serif', serif;
  font-weight: 100; /* Asegúrate de que el peso coincida con la fuente seleccionada */

}

.countdown-item label {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Dancing Script', cursive;

}






/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .fotoportada-container {
    padding-top: 0; /* Elimina el padding para móviles */
    background-attachment: scroll; /* Asegúrate de que se desplace correctamente */
  }
}

  .animated-text p {
    font-size: 20px; /* Reduce el tamaño del texto en pantallas pequeñas */
  
}

</style>