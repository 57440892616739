<template>
  <div class="footer-container">
    <div class="footer-content">
      <img
        src="@/assets/logoinvitacion.png"
        alt="Logo"
        class="footer-image"
      >
      <div class="footer-text">
        Copyright 2024 © <br>MI INVITACIÓN DIGITAL & MISS COQUETTE 
      </div>
    </div>

    <!-- Nueva sección de íconos debajo del footer -->
    <div class="footer-icons">
      <a
        href="https://www.facebook.com/profile.php?id=61564820404741"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/assets/facebook.png"
          alt="Facebook"
          class="footer-icon"
        >
      </a>
      <a
        href="https://www.instagram.com/myinvitaciondigital"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/assets/instagram.png"
          alt="Instagram"
          class="footer-icon"
        >
      </a>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    // Aquí puedes agregar lógica adicional si es necesario
  },
};
</script>

<style scoped>
/* Contenedor del pie de página */
.footer-container {
  background-color: black; /* Fondo negro */
  color: white; /* Texto blanco */
  padding: 20px; /* Espaciado interno */
  display: flex;
  flex-direction: column; /* Apilar el contenido de arriba hacia abajo */
  justify-content: center;
  align-items: center;
  width: 100vw; /* Ocupar el 100% del ancho de la ventana */
  margin: 40; /* Elimina márgenes para evitar desplazamiento */
  overflow: hidden; /* Oculta el desbordamiento */
}

/* Contenido del pie de página (imagen y texto) */
.footer-content {
  display: flex;
  align-items: center;
  width: 100%;
  
}

/* Estilo de la imagen */
.footer-image {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

/* Estilo del texto */
.footer-text {
  font-size: 12px;
  text-align: center;
}

/* Nueva sección de íconos */
.footer-icons {
  margin-top: 20px; /* Espacio entre el footer y los íconos */
  display: flex;
  gap: 10px; /* Espacio entre los íconos */

}

/* Estilo de los íconos */
.footer-icon {
  width: 30px; /* Tamaño pequeño para los íconos */
  height: auto;
}

/* Estilos para pantallas pequeñas (por ejemplo, móviles) */
@media (max-width: 576px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-image {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Estilos para pantallas medianas (tablets, etc.) */
@media (min-width: 577px) and (max-width: 768px) {
  .footer-image {
    width: 80px;
  }
}

/* Estilos para pantallas grandes */
@media (min-width: 769px) {
  .footer-image {
    width: 100px;
  }
}

</style>
