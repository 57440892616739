<template>
  <div>
    <!-- Contenedor Bienvenida Padres -->
    <div
      ref="padresRef"
      class="padres-container"
    >
      <!-- Texto 1 -->
      <div
        ref="texto1Ref"
        class="texto-container texto-novia"
      >
        Nuestros Padres
      </div>
      
      <!-- Texto 2 -->
      <div
        ref="texto2Ref"
        class="texto-container"
      >
        Novia: <br>
        Arantza Lopez Jimenez <br>
        Joel Francisco Duran Torres
      </div>
      
      <!-- Texto 3 -->
      <div
        ref="texto3Ref"
        class="texto-container"
      >
        Novio: <br>
        Fatima de Leon Cruz <br>
        Cesar Rodriguez Perez
      </div>
    </div>
  </div>
</template>
    
  <script>
  import { onMounted, ref, nextTick } from 'vue';
  import 'animate.css';
  
  export default {
    name: 'BienvenidaPadresPage',
    setup() {
      const padresRef = ref(null);
      const texto1Ref = ref(null);
      const texto2Ref = ref(null);
      const texto3Ref = ref(null);
  
      const observeElement = (element) => {
        if (element.value) {
          const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: [0.1],
          };
  
          const observerCallback = (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                element.value.classList.add('animate__animated', 'animate__fadeInUp');
                element.value.style.opacity = '1';
                element.value.style.transform = 'translateY(0)';
  
                element.value.addEventListener('animationend', () => {
                  element.value.classList.remove('animate__animated', 'animate__fadeInUp');
                }, { once: true });
              } else {
                element.value.style.opacity = '0';
                element.value.style.transform = 'translateY(50px)';
              }
            });
          };
  
          const observer = new IntersectionObserver(observerCallback, observerOptions);
          observer.observe(element.value);
        }
      };
  
      onMounted(async () => {
        await nextTick();
        observeElement(padresRef);
        observeElement(texto1Ref);
        observeElement(texto2Ref);
        observeElement(texto3Ref);
      });
  
      return { padresRef, texto1Ref, texto2Ref, texto3Ref };
    },
  };
  </script>
  
  <style scoped>
  /* Contenedor Bienvenida Padres */
  .padres-container {
    position: relative;
    width: 100%;
    text-align: center;
    background-image: url('../assets/fondopadres.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
  }
  
  /* Estilo para los textos */
  .texto-container {
    padding: 20px;
    margin: 10px 0;
    opacity: 0;
    font-size: 20ypx;
    font-family: 'Josefin Sans', sans-serif;
  font-weight: 350;    color: #333;
    border-radius: 10px;
    z-index: 2;
    width: 80%;
    transform: translateY(50px);
  }
  
  .texto-container.animate__animated {
    transform: translateY(0);
    opacity: 1;
  }
  

 
  </style>
  